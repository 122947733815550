import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../settings'
export const apiEndpoint = `${BASE_URL}/api`
import { objectToQueryString } from '../../../utility/Utils'

const INITIAL_STATE = {
  // GENERAL
  allOffers: [],
  offer: null,
  data: [],
  allData: [],
  total: 0,
  params: {},
  count: 0,
  isLoading: false,
  error: null
}
export const getAllOffersV2 = createAsyncThunk(
  'offers/getAllOffersV2',
  async (queryParams = { page_size: 10, page: 1 }) => {
    const queryString = objectToQueryString(queryParams)
    try {
      const response = await axios.get(
        `${apiEndpoint}/business/v3/offer?${queryString}`
      )
      return {
        data: response.data.results,
        allData: response.data.results,
        count: response.data.count,
        totalPages: Math.ceil(response.data.count / queryParams.page_size),
        vehicles: response.data.results
      }
    } catch (error) {
      console.log(error)
    }
  }
)
export const getAllOffers = createAsyncThunk(
  'offers/getAllOffers',
  async (offerStatus = {}) => {
    const queryString = objectToQueryString(offerStatus)
    try {
      const response = await axios.get(
        `${apiEndpoint}/business/v2/offer?${queryString}`
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)

export const getOffer = createAsyncThunk('offers/getOffer', async (offerId) => {
  try {
    const response = await axios.get(`${apiEndpoint}/business/offer/${offerId}`)

    return response.data
  } catch (error) {
    console.log(error)
  }
})

export const deleteOffer = createAsyncThunk(
  'appFinance/deleteOffer',
  async (id) => {
    try {
      const response = await axios.delete(
        `${apiEndpoint}/business/offer/${id}`
      );
      return id;
    } catch (error) {
      console.log(error)
      throw error
    }
  }
);

export const updateOffer = createAsyncThunk(
  'offers/updateOffer',
  async ({ offerId, data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${apiEndpoint}/business/offer/${offerId}`,
        data
      )
      if (response.status === 200) {
        return response.data
      } else {
        // Ako status nije 200, odbacite sa custom vrednošću
        return rejectWithValue({
          errorMessage: 'Something went wrong!',
          status: response.status
        })
      }
    } catch (error) {
      // Uhvatite druge greške (npr. mrežne greške)
      return rejectWithValue({
        errorMessage: 'Something went wrong!',
        status: 0
      })
    }
  }
)

export const generateLongTermContract = createAsyncThunk(
  'offers/generateLongTermContract',
  async ({ offerId, contractData }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${apiEndpoint}/business/offer/${offerId}/generate_contract`,
        contractData
      )

      if (response.status === 200) {
        return response.data
      } else {
        return rejectWithValue({
          errorMessage: 'Something went wrong!',
          status: response.status
        })
      }
    } catch (error) {
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          errorMessage: error.response.data
        })
      }
    }
  }
)

const offersSlice = createSlice({
  name: 'offers',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllOffersV2.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getAllOffersV2.fulfilled, (state, action) => {
        try {
          state.data = action.payload.data
          state.allData = action.payload.allData
          state.total = action.payload.totalPages
          state.count = action.payload.count
          state.params = action.payload.params
        } catch (error) {
          console.log(error)
        }
        state.isLoading = false
      })
      .addCase(getAllOffers.fulfilled, (state, action) => {
        try {
          state.allOffers = action.payload
          state.isLoading = false
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(getAllOffers.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getAllOffers.rejected, (state, action) => {
        state.isLoading = false
      })
      .addCase(getOffer.fulfilled, (state, action) => {
        state.offer = action.payload
        state.isLoading = false
      })
      .addCase(getOffer.pending, (state, action) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(deleteOffer.fulfilled, (state, action) => {
        try {
          state.allData = state.allData.filter(
            (offer) => offer.id !== action.payload
          )
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(updateOffer.pending, (state, action) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(updateOffer.fulfilled, (state, action) => {
        try {
          const offerId = action?.payload?.id
          if (!offerId) return
          state.allOffers = state.allOffers.map((offer) => {
            return offer.id === offerId ? action.payload : offer
          })
          state.offer = { ...state.offer, ...action?.payload }
        } catch (error) {
          console.log(error)
        } finally {
          state.isLoading = false
        }
      })
      .addCase(updateOffer.rejected, (state, action) => {
        state.error = action.payload
        state.isLoading = false
      })
  }
})

export default offersSlice.reducer
